import { useCallback, useEffect, useRef, useState } from "react";
import { scroller } from 'react-scroll'
import classNames from "classnames";

import { useOnClickOutside } from "../utils/useOnClickOutside";

import "./index.scss"

const FIRST_TEXT_MENU = {
    "contacts": {
        text: "Main Page",
        url: "master"
    },
    "master": {
        text: "Contact Us",
        url: "contacts"
    }
}

export const Menu = ({ theme, isMenuOpen, setIsMenuOpen, withBg, withGrayBg }) => {
    const menuItemsRef = useRef();
    const [block, setBlock] = useState("master");

    const closeMenu = useCallback(() => {
        setIsMenuOpen(false)
    }, [setIsMenuOpen]);

    const openMenu = useCallback(() => {
        setIsMenuOpen(true)
    }, [setIsMenuOpen]);

    const scrollToElement = (elem, nameBlock) => {
        closeMenu();
        scroller.scrollTo(nameBlock, {
            duration: 400,
            delay: 100,
            smooth: "easeInOutQuart",
        })
    }

    const clickHandlerServices = () => {
        const servicesWrapper = document.querySelector(".wrapper-services")
        scrollToElement(servicesWrapper, "services")
    }

    const clickHandlerProjects = () => {
        const projectsWrapper = document.querySelector(".wrapper-projects")
        scrollToElement(projectsWrapper, "projects")
    }

    const clickHandlerContacts = () => {
        if (block === "master") {
            const contactsWrapper = document.getElementById("contacts")
            scrollToElement(contactsWrapper, "contacts")
        } else {
            const mainWrapper = document.querySelector(".main-wrapper")
            scrollToElement(mainWrapper, "main")
        }
    }

    useOnClickOutside(menuItemsRef, closeMenu);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const contacts = document.getElementById("contacts");
            const { top } = contacts.getBoundingClientRect();

            if (top <= window.innerHeight/2) {
                setBlock("contacts")
            } else if (block !== "master") {
                setBlock("master")
            }
        })
    }, [block])

    return (
        <div className={classNames("header-menu", { lightTheme: theme === "light", open1: isMenuOpen, withBg: withBg, withGrayBg: withGrayBg })}>
            <div className="menu-background" />
            <div
                className={classNames("wrapper-burger", { open: isMenuOpen })}
                onMouseEnter={openMenu}
            >
                <div className="first-line line" onMouseEnter={openMenu} onClick={openMenu}/>
                <div className="second-line line" onMouseEnter={openMenu} onClick={openMenu}/>
                <div className="third-line line" onMouseEnter={openMenu} onClick={openMenu}/>
                <div className="close" onClick={closeMenu} />

                <div className="menu-items vertical-orientation" ref={menuItemsRef}>
                    <div
                        className="first-item item"
                        onMouseLeave={closeMenu}
                        onMouseEnter={openMenu}
                        onClick={clickHandlerContacts}
                    >
                        <span className="hover-mobile">
                            {block === "contacts" ? FIRST_TEXT_MENU.contacts.text : FIRST_TEXT_MENU.master.text}
                        </span>
                    </div>
                    <div
                        className="second-item item"
                        onClick={clickHandlerServices}
                        onMouseLeave={closeMenu}
                        onMouseEnter={openMenu}
                    >
                        <span className="hover-mobile">
                            Services
                        </span>
                    </div>
                    <div
                        className="third-item item"
                        onClick={clickHandlerProjects}
                        onMouseLeave={closeMenu}
                        onMouseEnter={openMenu}
                    >
                        <span className="hover-mobile">
                            Projects
                        </span>
                    </div>
                </div>
            </div>
        </div> 
    )
}