import "./index.css"

const Footer = () => {
    return (
        <div className="app-footer">
            <div className="pseudo-background-black pseudo-background" >
                <div className="first-background"/>
            </div>
            <div className="form-footer"></div>
            {/* <div className="socials">
                <a className="icon facebook" title="icon-facebook" href="https://facebook.com/" target="_blank"/>
                <a className="icon instagram" title="icon-instagram" href="https://www.instagram.com/" target="_blank"/>
                <a className="icon linkedin" title="icon-linkedin" href="https://linkedin.com/" target="_blank"/>
                <a className="icon youtube" title="icon-youtube" href="https://www.youtube.com/" target="_blank"/>
            </div> */}
        </div>
    )
}

export default Footer