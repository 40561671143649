import classNames from "classnames";
import { useInitialHeight } from "../../utils/useInitialHeight";

import "./index.scss"

const Video = ({ ref, url, children, isBorderRadius = true, posterUrl }) => {
    const initialHeight = useInitialHeight();
    return (
        <div
            ref={ref}
            className={classNames("wrapper-video", { radius: isBorderRadius })}
            style={{ height: initialHeight }}
        >
            <video
                autoPlay
                loop
                muted
                playsInline
                className={classNames("video", { radius: isBorderRadius })}
                poster={posterUrl}
            >
                <source src={url} type="video/mp4" />
            </video>
            {children}
        </div>
    )
}

export default Video