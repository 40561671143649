import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";

import { Menu } from "./Menu";
import { Logo } from "./Logo";

import "./index.scss"

const Header = ({ theme }) => {
    const [isDarkBackground, setIsDarkBackground] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [withBg, setWithBg] = useState(false);
    const [withGrayBg, setWithGrayBg] = useState(false);

    const isDesktop = window.innerWidth > 1024;

    const changeHeaderBackground = useCallback(() => {
        const percentScrolled = window.scrollY / window.innerHeight;

        let percentScrolledLimit = 0.5;

        if (window.innerWidth < 480) {
            percentScrolledLimit = 0.4;
        }

        if (percentScrolled <= percentScrolledLimit) {
            setIsDarkBackground(false);
        } else {
            setIsDarkBackground(true);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", changeHeaderBackground)

        return () => {
            window.removeEventListener("scroll", changeHeaderBackground);
        };
    }, [changeHeaderBackground]);

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            const { top } = document.getElementById("wrapper-second-page").getBoundingClientRect();

            const grayBg = document.querySelector(".pseudo-background-white.pseudo-background .first-background");
            const { top: grayBgTop } = grayBg.getBoundingClientRect();
        
            if (top <= 47) {
                setWithBg(true);
            } else {
                setWithBg(false);
            }

            if (grayBgTop <= 47) {
                grayBg.classList.add("grayBg")
                setWithGrayBg(true)
            } else {
                grayBg.classList.remove("grayBg");
                setWithGrayBg(false)
                
            }
        });
    }, [theme]);

    return (
        <div className={classNames("header", { dark: isDarkBackground && window.innerWidth <= 480 })}>
            <div className="header-container">
                {isDesktop && <Logo theme={theme} isMenuOpen={isMenuOpen} withBg={withBg} withGrayBg={withGrayBg} />}
                <Menu
                    theme={theme}
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                    withBg={withBg}
                    withGrayBg={withGrayBg}
                />
            </div>
        </div>
    )
}

export default Header