import classNames from "classnames";
import { scroller } from "react-scroll";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useLandscape } from "../utils/useLandscape";

import "./index.scss"

export const Logo = ({ theme, isMenuOpen, withBg, withGrayBg }) => {
    const heightRef = useRef(window.innerHeight);
    const logoRef = useRef();
    const logoWrapper = useRef();
    const [logoInitialSize, setLogoInitialSize] = useState({});
    const isLandscape = useLandscape();

    const setDefaults = useCallback(() => {
        const defaultLogoSize = window.innerWidth > 1280
            ? [826, 145]
            : window.innerWidth > 830
                ? [767, 123]
                : window.innerWidth > 650
                    ? [554, 103]
                    : window.innerWidth > 460
                        ? [387, 73]
                        : [289, 51];
        
        const [defaultWidth, defaultHeight] = defaultLogoSize;
                        
        setLogoInitialSize({
            width: defaultWidth,
            height: defaultHeight,
            left: window.innerWidth/2 - defaultWidth/2 - logoWrapper.current.getBoundingClientRect().left,
            top: window.innerHeight/2 - defaultHeight/2 - 48,
            wrapperLeft: logoWrapper.current.getBoundingClientRect().left,
        });
    }, []);

    useEffect(() => {
        if (!logoInitialSize.left) {
           setDefaults();
        }
    }, [logoInitialSize.left, setDefaults]);

    useEffect(() => {
        setDefaults(); 
    }, [isLandscape, setDefaults]);
    
    const isMobile = window.innerWidth <= 480;
    const isTablet = window.innerWidth <= 1024 &&  window.innerWidth > 480;

    const { scrollYProgress } = useScroll({
        target: logoWrapper,
        offset: [`end ${isMobile || isTablet ? "50vh" : "35vh"}`, "start start"]
    });

    const x = useTransform(scrollYProgress, [1, 0], [logoInitialSize.left, 0]);
    const y = useTransform(scrollYProgress, [1, 0], [window.innerHeight/2 - logoInitialSize.height/2 - 48, 0 - logoInitialSize.height/2 + 15]);
    const scale = useTransform(scrollYProgress, [1, 0], [1, isMobile ? 0.56 : isTablet ? 0.36 : 0.26]);

    const motionDivStyle = useMemo(() => ({ 
        translateX: x, 
        translateY: y, 
        scale,
        originX: 0
    }), [scale, y, x]);

    const scrollToTop = useCallback(() => {
        scroller.scrollTo("main", {
            duration: 400,
            smooth: "easeInOutQuart",
        })
    }, []);

    const logoFill = theme === "light" ?  "#13141C" : "#fff";

    const isHiddenLogoLetters = window.innerWidth < 960 && window.innerWidth > 831 && isMenuOpen;
    const logoLetterClass = classNames("logo-letter", { "logo-letter-hidden": isHiddenLogoLetters });

    useEffect(() => {
        if (isMobile) {
            window.addEventListener("scroll", (e) => {
                const headerLogo = document.getElementById("header-logo");

                // if (window.scrollY >= 25) {
                //     headerLogo.classList.add("corner")
                // } else if (window.scrollY <= 5) {
                //     headerLogo.classList.remove("corner")
                // }
            });
        }
        
    }, [isMobile]);

    if (isMobile) {
        return (
            <div
                className={classNames("header-logo", theme)}
                ref={logoWrapper}
            >
                <div
                    ref={logoRef}
                    // style={motionDivStyle}
                    onClick={scrollToTop}
                >
                    <svg 
                        className={classNames("header-logo__svg")} 
                        id="header-logo"
                        width="826" height="145" viewBox="0 0 826 145" fill="none" xmlns="http://www.w3.org/2000/svg"
                        // style={style}
                    >
                        <path d="M20.6662 0L56 79.0909V87.8788L20.6662 145C16.0575 87.8788 33.9804 86.9024 40.6375 84.9495C-8.5225 61.5152 6.84 18.5522 20.6662 0Z" fill="#444444"/>
                        <path className="symbol" d="M40 85C-4.26387 80.2 -1.75658 37.5 3.58226 16L56 79V88L6.49435 139C6.49435 100.6 27.8663 86.5 40 85Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M123.468 82.4997L155.066 133.045H139.337L115.533 95.3527L91.8689 133.045H76L107.599 82.4997L76 31.9541H91.8689L115.533 69.5022L139.337 31.9541H155.066L123.468 82.4997Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M257.852 109.195C253.208 116.513 246.983 122.34 239.18 126.676C231.377 130.922 222.923 133.045 213.819 133.045C204.437 133.045 195.751 130.787 187.762 126.27C179.772 121.753 173.455 115.609 168.811 107.84C164.166 100.071 161.843 91.6241 161.843 82.4997C161.843 73.3753 164.166 64.9284 168.811 57.1591C173.455 49.3898 179.772 43.2467 187.762 38.7297C195.751 34.2126 204.437 31.9541 213.819 31.9541C222.923 31.9541 231.377 34.1223 239.18 38.4586C246.983 42.7046 253.208 48.4413 257.852 55.6685L247.123 62.3085C243.593 56.7978 238.901 52.4614 233.049 49.2995C227.196 46.0472 220.833 44.4211 213.959 44.4211C206.898 44.4211 200.396 46.1376 194.45 49.5705C188.505 52.9131 183.767 57.5205 180.237 63.3926C176.707 69.1744 174.942 75.5434 174.942 82.4997C174.942 89.3656 176.707 95.7346 180.237 101.607C183.767 107.389 188.505 111.996 194.45 115.429C200.396 118.771 206.898 120.443 213.959 120.443C220.833 120.443 227.196 118.862 233.049 115.7C238.901 112.448 243.593 108.111 247.123 102.691L257.852 109.195Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M484.321 31.9541L527.807 133.045H513.172L484.321 63.7894L455.469 133.045H440.834L484.321 31.9541Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M705.706 133.045L662.22 31.9541L676.855 31.9541L705.706 101.21L734.558 31.9541L749.193 31.9541L705.706 133.045Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M617.039 45.096H582.394V133.045H569.229V45.096H534.584V31.9541H617.039V45.096Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M646.407 31.9541H632.852V133.045H646.407V31.9541Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M328.129 35.9978C323.811 33.302 319.034 31.9541 313.798 31.9541H272.457V44.1169H285.548V44.085H312.42C317.105 44.085 321.055 45.818 324.27 49.284C327.578 52.75 329.231 56.9381 329.231 61.8482C329.231 66.7583 327.578 70.9464 324.27 74.4124C321.055 77.7821 317.105 79.4669 312.42 79.4669H312.069V91.7423H312.144L331.16 133.045H345.63L325.373 88.854C330.517 86.5433 334.651 82.9811 337.775 78.1672C340.898 73.257 342.46 67.8174 342.46 61.8482C342.46 56.4567 341.174 51.4984 338.602 46.9734C336.029 42.3521 332.538 38.6935 328.129 35.9978Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M377.581 74.5568V74.5598H364.027V133.045H423.892V120.77H377.041V86.2545H421.837V74.5568H377.581Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M377.581 44.2295V44.2211H364.027V31.9541H423.892V44.2295H377.581Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M779.69 74.5568V74.5598H766.136V133.045H826V120.77H779.15V86.2545H823.945V74.5568H779.69Z" fill={logoFill}/>
                        <path className={logoLetterClass} d="M779.69 44.2295V44.2211H766.136V31.9541H826V44.2295H779.69Z" fill={logoFill}/>
                    </svg>
                </div> 
            </div>
        )
    }
    

    return (
        <div
            className={classNames("header-logo", theme, { withBg: withBg, withGrayBg: withGrayBg })}
            ref={logoWrapper}
            style={{ height: `${heightRef.current}px` }}
        >
            <motion.div
                ref={logoRef}
                style={motionDivStyle}
                onClick={scrollToTop}
            >
                <svg 
                    className={classNames("header-logo__svg withAnimation")} 
                    width="826" height="145" viewBox="0 0 826 145" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M20.6662 0L56 79.0909V87.8788L20.6662 145C16.0575 87.8788 33.9804 86.9024 40.6375 84.9495C-8.5225 61.5152 6.84 18.5522 20.6662 0Z" fill="#444444"/>
                    <path className="symbol" d="M40 85C-4.26387 80.2 -1.75658 37.5 3.58226 16L56 79V88L6.49435 139C6.49435 100.6 27.8663 86.5 40 85Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M123.468 82.4997L155.066 133.045H139.337L115.533 95.3527L91.8689 133.045H76L107.599 82.4997L76 31.9541H91.8689L115.533 69.5022L139.337 31.9541H155.066L123.468 82.4997Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M257.852 109.195C253.208 116.513 246.983 122.34 239.18 126.676C231.377 130.922 222.923 133.045 213.819 133.045C204.437 133.045 195.751 130.787 187.762 126.27C179.772 121.753 173.455 115.609 168.811 107.84C164.166 100.071 161.843 91.6241 161.843 82.4997C161.843 73.3753 164.166 64.9284 168.811 57.1591C173.455 49.3898 179.772 43.2467 187.762 38.7297C195.751 34.2126 204.437 31.9541 213.819 31.9541C222.923 31.9541 231.377 34.1223 239.18 38.4586C246.983 42.7046 253.208 48.4413 257.852 55.6685L247.123 62.3085C243.593 56.7978 238.901 52.4614 233.049 49.2995C227.196 46.0472 220.833 44.4211 213.959 44.4211C206.898 44.4211 200.396 46.1376 194.45 49.5705C188.505 52.9131 183.767 57.5205 180.237 63.3926C176.707 69.1744 174.942 75.5434 174.942 82.4997C174.942 89.3656 176.707 95.7346 180.237 101.607C183.767 107.389 188.505 111.996 194.45 115.429C200.396 118.771 206.898 120.443 213.959 120.443C220.833 120.443 227.196 118.862 233.049 115.7C238.901 112.448 243.593 108.111 247.123 102.691L257.852 109.195Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M484.321 31.9541L527.807 133.045H513.172L484.321 63.7894L455.469 133.045H440.834L484.321 31.9541Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M705.706 133.045L662.22 31.9541L676.855 31.9541L705.706 101.21L734.558 31.9541L749.193 31.9541L705.706 133.045Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M617.039 45.096H582.394V133.045H569.229V45.096H534.584V31.9541H617.039V45.096Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M646.407 31.9541H632.852V133.045H646.407V31.9541Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M328.129 35.9978C323.811 33.302 319.034 31.9541 313.798 31.9541H272.457V44.1169H285.548V44.085H312.42C317.105 44.085 321.055 45.818 324.27 49.284C327.578 52.75 329.231 56.9381 329.231 61.8482C329.231 66.7583 327.578 70.9464 324.27 74.4124C321.055 77.7821 317.105 79.4669 312.42 79.4669H312.069V91.7423H312.144L331.16 133.045H345.63L325.373 88.854C330.517 86.5433 334.651 82.9811 337.775 78.1672C340.898 73.257 342.46 67.8174 342.46 61.8482C342.46 56.4567 341.174 51.4984 338.602 46.9734C336.029 42.3521 332.538 38.6935 328.129 35.9978Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M377.581 74.5568V74.5598H364.027V133.045H423.892V120.77H377.041V86.2545H421.837V74.5568H377.581Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M377.581 44.2295V44.2211H364.027V31.9541H423.892V44.2295H377.581Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M779.69 74.5568V74.5598H766.136V133.045H826V120.77H779.15V86.2545H823.945V74.5568H779.69Z" fill={logoFill}/>
                    <path className={logoLetterClass} d="M779.69 44.2295V44.2211H766.136V31.9541H826V44.2295H779.69Z" fill={logoFill}/>
                </svg>
                
            </motion.div>
            <div className="logo-background" />
        </div>
    )
}