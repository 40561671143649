export function getMobileScreenSize() {
    const containerWidth = document.body.clientWidth;
    const containerHeight = document.body.clientHeight;

    return {
        width: window.innerWidth,
        height: window.innerHeight,
        containerWidth: containerWidth,
        containerHeight: containerHeight,
        left: 0,
        top: 0,
        scale: 1,
    };
}
