import { useState, useEffect, useCallback } from 'react'

const getParameter = (breakpoints) => {
  const {
    desktop,
    tabletBig,
    tabletSmall,
    phoneBig, 
    phoneSmall,
  } = breakpoints;

  let parameter = desktop;

  if (window.innerWidth <= 375) {
      parameter = phoneSmall || phoneBig || tabletSmall || tabletBig || desktop;
  } else if (window.innerWidth <= 480 && window.innerWidth > 375) {
    parameter = phoneBig || tabletSmall || tabletBig || desktop;
  } else if (window.innerWidth <= 768 && window.innerWidth > 480) {
    parameter = tabletSmall || tabletBig || desktop;
  } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
    parameter = tabletBig || desktop;
  } else if (window.innerWidth > 1024) {
    parameter = desktop;
  }
  
  return parameter;
}

export const useWindowWidth = (breakpoints) => {
  const [parameter, setParameter] = useState(breakpoints.desktop);

  const handleSetParameter = useCallback(() => {
    setParameter(getParameter(breakpoints));
  }, [breakpoints]);
  
  useEffect(() => {
    window.addEventListener(
      'resize',
      handleSetParameter
    )
    return () => {
      window.removeEventListener(
        'resize',
        handleSetParameter
      )
    }
  }, [])

  return parameter
}