
export function changeBlockHeightOnScroll(block, breakpoints, log = false) {
    if (!block) {
        return;
    }

    const {
        desktop,
        tabletBig,
        tabletSmall,
        phoneBig, 
        phoneSmall,
    } = breakpoints;

    let heights = desktop;
    let offsetTop = 0;

    if (window.innerWidth <= 375) {
        heights = phoneSmall;
        offsetTop = 200;
    } else if (window.innerWidth <= 480 && window.innerWidth > 375) {
        heights = phoneBig;
    } else if (window.innerWidth <= 768 && window.innerWidth > 480) {
        heights = tabletSmall;
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
        heights = tabletBig;
    } else if (window.innerWidth > 1024) {
        heights = desktop;
    }
    const [startHeight, endHeight, offsetBottom] = heights;

    const windowHeight = window.innerHeight - (offsetBottom || 0);
    const blockTop = block && block.getBoundingClientRect().top - offsetTop;

    block.style.minHeight = startHeight + "px";
    
    // const children = block.children[0];
    const { parentElement } = block;
    const video = block.querySelector('video');
    if (video) video.style.minHeight = startHeight + "px";
    parentElement.style.height = endHeight + 'px';
    const newBlockHeight = endHeight - endHeight * (blockTop)/windowHeight;

    if (blockTop > 0 && blockTop < windowHeight) {
        if (video) video.style.height = `${newBlockHeight}px`;
        block.style.height = `${newBlockHeight}px`;
    }
}
  