import { useEffect, useState } from "react";
import { getMobileScreenSize } from "./getMobileScreenSize";

export const useLandscape = () => {
    const [isLandscape, setIsLandscape] = useState(false);

    const check = () => {
        const dimensions = getMobileScreenSize();
    
        setIsLandscape(dimensions.width > dimensions.height);
    }

    useEffect(() => {
        window.addEventListener(
            'resize',
            check
        )
        return () => {
            window.removeEventListener(
                'resize',
                check
            )
        }
    }, [])

    return isLandscape;

}